/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import { isWebview } from '../../utils/helper';

function Home() {
  const navigate = useNavigate();
  // const handleSubmit = () => {
  //   localStorage.setItem("newUser", "false");
  //   navigate("/explore");
  // };

  const isNewUser = localStorage.getItem("newUser");
  const userRole = localStorage.getItem("role");
  // const checkIsWebview = isWebview();


  useEffect(() => {
    // if (checkIsWebview) {
    // if (isNewUser === "false") {
    //   navigate("/auth/signin");
    // } else {
    //   navigate("/explore");
    // }
    navigate("/auth/signin");

    // } else {
    //   //Always Show Explore page on Web: Jekaeat.io 
    //   navigate("/explore");
    // }

  }, [isNewUser, navigate, userRole]);

  return <div className="home max-w-[600px] mx-auto"></div>;
}
export default Home;
